import {
    Card, Container, Stack,
    Menu, Typography, Button,
    FormControl, Select, MenuItem,
    InputLabel, TextField, InputAdornment,
    CardContent, IconButton, CardHeader, Box,
    Modal,
    Backdrop,
    Fade,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@mui/material';
import React, { useState } from 'react'
import CreatingFormsView from './Views/CreatingFormsView';
import SearchIcon from '@mui/icons-material/Search';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import AddIcon from '@mui/icons-material/Add';
import Pagination from '@mui/material/Pagination';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import DeleteIcon from '@mui/icons-material/Delete';
import DotsColor from 'src/components/color-utils/DotsColor';
import FormsStep1 from './Views/FormsStep1';
import FormsStep2 from './Views/FormsStep2';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import '../pages/createForm.css';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
const CreateForm = () => {

    const [formStep1, setFormStep1] = useState({
        surveyTitle: '',
        surveyCategories: '',
        surveyDesc: ''
    });
    const [activeStep, setActiveStep] = useState(0);
    const [openDialogForm, setOpenDialogForm] = useState(false);
    const [openCloseConfirmation, setOpenCloseConfirmation] = useState(false);
    const handleCloseModalConfirm = () => {
        setOpenCloseConfirmation(false);
        setOpenModal(false);
    };

    const handleCloseConfirmation = () => {
        setOpenCloseConfirmation(false);
    };
    const handleOpenCloseConfirmation = () => {
        setOpenCloseConfirmation(true);
    };
    const handleOpenDialog = () => {
        setOpenDialogForm(true);
    };

    const handleCloseDialog = () => {
        setOpenDialogForm(false);
    };

    const handleConfirm = () => {
        setActiveStep(activeStep - 1);
        handleCloseDialog();
    };

    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpenModal(false);
        }
    };
    const [subAnchorEl, setSubAnchorEl] = useState(null);

    const handleClickSubMenu = (event) => {
        setSubAnchorEl(event.currentTarget);
    };

    const handleSubMenuClose = () => {
        setSubAnchorEl(null);
    };
    const [page, setPage] = useState(1);
    const itemsPerPage = 9;
    const cardArray = [
        { id: 0, name: "Veri 1", status: "Aktif" },
        { id: 1, name: "Veri 2", status: "Beklemede" },
        { id: 2, name: "Veri 3", status: "Aktif" },
        { id: 3, name: "Veri 4", status: "Taslak" },
        { id: 4, name: "Veri 5", status: "Aktif" },
        { id: 5, name: "Veri 6", status: "Beklemede" },
        { id: 6, name: "Veri 7", status: "Aktif" },
        { id: 7, name: "Veri 8", status: "Beklemede" },
        { id: 8, name: "Veri 9", status: "Taslak" },
        { id: 9, name: "Veri 10", status: "Beklemede" },
        { id: 10, name: "Veri 11", status: "Aktif" },
        { id: 11, name: "Veri 12", status: "Beklemede" },
        { id: 12, name: "Veri 13", status: "Aktif" },
        { id: 13, name: "Veri 14", status: "Beklemede" },
        { id: 14, name: "Veri 15", status: "Aktif" },
        { id: 15, name: "Veri 16", status: "Taslak" },
        { id: 16, name: "Veri 17", status: "Aktif" },
        { id: 17, name: "Veri 18", status: "Beklemede" }
    ];

    const paginatedData = cardArray.slice((page - 1) * itemsPerPage, page * itemsPerPage);

    const handleChange = (event, value) => {
        setPage(value);
    };

    const [anchorEl, setAnchorEl] = useState(null);

    const handleExpandClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Container>

            <Card sx={{ boxShadow: '2px 1px 4px 0px rgba(0, 0, 0, 0.1)' }}>
                <Stack spacing={4} padding={4}>
                    <Stack>
                        <Typography variant="h3">Anket Oluşturma Paneli</Typography>
                    </Stack>
                    <Stack flexDirection="row" justifyContent="space-between">
                        <FormControl sx={{ flex: 1 }}>
                            <TextField
                                variant="outlined"
                                placeholder="Anket yada kategori adı bulun.."
                                sx={{
                                    width: '300px', '& .MuiOutlinedInput-root': {
                                        backgroundColor: 'white',
                                    }
                                }}
                                // value={username}
                                // onChange={(e) => setUsername(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </FormControl>
                        <Stack flexDirection="row" sx={{ gap: '15px' }}>
                            <FormControl>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        sx={{
                                            backgroundColor: '#ed9a9740', '& .MuiOutlinedInput-root': {
                                                backgroundColor: 'white',
                                            }
                                        }}
                                        format="DD/MM/YYYY"
                                        label="Tarih Seçin"
                                    // value={startDate}
                                    // onChange={(newValue) => setStartDate(newValue)}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                            <Button variant='contained' endIcon={<AddIcon />} onClick={handleOpenModal}> Anket Oluştur</Button>
                        </Stack>



                    </Stack>

                    <Stack flexDirection="row" sx={{ gap: '15px' }} justifyContent="space-between" flexWrap="wrap">
                        {paginatedData.map((e, i) => (
                            <Card key={i} sx={{ maxWidth: 400, width: '340px', border: '1px solid #f1dbdb' }}>

                                <CardHeader
                                    avatar={
                                        <img
                                            src={require('../../src/assets/images/form_frame_stage.png')}
                                            alt="Pop-up Error"
                                            style={{
                                                display: 'block',
                                                margin: '20px auto',
                                                maxWidth: '100%',
                                                height: 'auto',
                                            }}
                                        />
                                    }
                                    action={
                                        <IconButton onClick={handleExpandClick} aria-label="settings">
                                            <MoreHorizIcon />
                                        </IconButton>
                                    }


                                />
                                <CardContent>
                                    <Typography variant="h5" sx={{ color: 'text.primary' }}>
                                        {e.name}
                                    </Typography>
                                    <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                                        <Typography variant="p" sx={{ color: 'text.secondary' }}>
                                            ML • 19.09.24
                                        </Typography>
                                        {e.status === 'Aktif' && <DotsColor color='#067647' name={e.status} border={true} />}
                                        {e.status === 'Beklemede' && <DotsColor color='rgb(222 126 2)' name={e.status} border={true} />}
                                        {e.status === 'Taslak' && <DotsColor color='gray' name={e.status} border={true} />}

                                    </Stack>

                                </CardContent>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    <Box sx={{ width: '250px' }}>

                                        <MenuItem onClick={handleClose} disableRipple sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Typography variant="p" sx={{ color: 'text.primary' }}>
                                                Düzenle
                                            </Typography>
                                            <EditIcon />
                                        </MenuItem>
                                        <Divider sx={{ my: '5px !important' }} />

                                        <MenuItem onClick={handleClose} disableRipple sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Typography variant="p" sx={{ color: 'text.primary' }}>
                                                Sil
                                            </Typography>
                                            <DeleteIcon />
                                        </MenuItem>
                                        <Divider sx={{ my: '5px !important' }} />

                                        {/* Status Alt Menüsü */}
                                        <MenuItem
                                            aria-controls="status-menu"
                                            aria-haspopup="true"
                                            onClick={handleClickSubMenu} // Alt menü için açma fonksiyonu
                                            disableRipple
                                            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                        >
                                            <Typography variant="p" sx={{ color: 'text.primary' }}>
                                                Status
                                            </Typography>
                                            <KeyboardArrowRightIcon />
                                        </MenuItem>

                                        <Menu
                                            id="status-menu"
                                            anchorEl={subAnchorEl} // Alt menü için ayrı anchor
                                            open={Boolean(subAnchorEl)} // Alt menünün açık olup olmadığını kontrol ediyoruz
                                            onClose={handleSubMenuClose} // Alt menü kapandığında çalışacak fonksiyon
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right', // Menü, ana menünün sağında açılacak
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left', // Menü sağa kaydırılacak şekilde hizalanacak
                                            }}
                                        >
                                            <Box sx={{ minWidth: '200px' }}>
                                                <MenuItem onClick={handleClose} disableRipple sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <Typography variant="p" sx={{ color: 'text.primary' }}>
                                                        Aktif
                                                    </Typography>
                                                    <DotsColor color='#067647' border={false} />
                                                </MenuItem>
                                                <MenuItem onClick={handleClose} disableRipple sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <Typography variant="p" sx={{ color: 'text.primary' }}>
                                                        Beklemede
                                                    </Typography>
                                                    <DotsColor color='rgb(222 126 2)' border={false} />
                                                </MenuItem>
                                                <MenuItem onClick={handleClose} disableRipple sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <Typography variant="p" sx={{ color: 'text.primary' }}>
                                                        Taslak
                                                    </Typography>
                                                    <DotsColor color='gray' border={false} />
                                                </MenuItem>
                                            </Box>
                                        </Menu>

                                    </Box>
                                </Menu>

                            </Card>
                        ))}

                    </Stack>
                </Stack>
                <Box display="flex" justifyContent="center" sx={{ mt: 2, mb: 2 }}>
                    <Pagination

                        count={Math.ceil(cardArray.length / itemsPerPage)}
                        page={page}
                        onChange={handleChange}
                        shape="rounded"
                        sx={{
                            "& .Mui-selected": {
                                backgroundColor: '#da3831 !important', 
                                color: 'white !important', 
                            },
                            "& .MuiPaginationItem-root": {
                                color: '#E0735A', 
                                borderColor: '#da3831', 
                                fontSize: '1.10rem',
                                width: '42px',
                                height: '42px'
                            },
                            "& .MuiPaginationItem-root:hover": {
                                backgroundColor: '#f9cbc9', 
                            },
                            "& .MuiPaginationItem-previousNext": {
                                border: '1px solid #E0735A'
                            }


                        }}
                    />
                </Box>
            </Card>


            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                onClose={handleCloseModal}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={openModal}>
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        border: '1px solid white',
                        boxShadow: 24,
                        p: 4,
                        width: activeStep === 0 ? '28%' : '60%',
                        borderRadius: '6px',
                        overflowY: 'auto',
                        maxHeight: '90vh',
                    }}>
                        <Stack sx={{ gap: '10px' }}>

                            {activeStep === 0 && <FormsStep1 setFormStep1={setFormStep1} formStep1={formStep1} activeStep={activeStep}  handleOpenCloseConfirmation={handleOpenCloseConfirmation} setActiveStep={setActiveStep} />}
                            {activeStep === 1 && <FormsStep2  formStep1={formStep1}    handleOpenCloseConfirmation={handleOpenCloseConfirmation} />}

                            < >
                                {activeStep === 1 &&
                                    <button type='button' onClick={handleOpenDialog}
                                        style={{ cursor: 'pointer', height: '45px', width: '45px', position: 'absolute', top: '5px', left: '10px', background: 'transparent', border: 0 }} variant="contained" color="primary">
                                        <ArrowBackIosIcon />
                                    </button>
                                }
                            </>
                        </Stack>
                        <Dialog
                            open={openDialogForm}
                            onClose={handleCloseDialog}
                            aria-labelledby="confirm-dialog-title"
                            aria-describedby="confirm-dialog-description"
                        >
                            <DialogTitle id="confirm-dialog-title">Önceki Adıma Dön</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="confirm-dialog-description">
                                    Değişiklikleriniz kaydedilmedi Önceki adıma dönmek istediğinize emin misiniz?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseDialog} color="primary">
                                    İptal
                                </Button>
                                <Button onClick={handleConfirm} color="primary" autoFocus>
                                    Evet
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog open={openCloseConfirmation} onClose={handleCloseConfirmation} aria-labelledby="close-dialog-title" aria-describedby="close-dialog-description">
                            <DialogTitle id="close-dialog-title">Anketi Kapat</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="close-dialog-description">
                                    Anketi kapatmak istediğinize emin misiniz?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseConfirmation} color="primary">
                                    İptal
                                </Button>
                                <Button onClick={handleCloseModalConfirm} color="primary" autoFocus>
                                    Evet
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Box>
                </Fade>
            </Modal>
        </Container>
    )

}

export default CreateForm
